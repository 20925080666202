<script setup>
// import "animate.css";
import { computed, defineProps, onMounted, onUnmounted, ref, watch } from "vue";
import { useL24Store } from "../store/l24";
import UAParser from "ua-parser-js";
import dayjs from "dayjs";

const store = useL24Store();

const props = defineProps({
	options: {
		type: Object,
	},
});

const initLoad = ref(false);
const isMobile = ref(false);
const popupOpen = ref(false);

const wstore = ref({
	privew: false,
	zIndex: 999999,
	width: "320px",
	aspectRatio: "9/16",
	borderRadius: "10px",
	livePlayerLink: "",
	privewType: "live",
});

const todayNo = () => {
	let today = dayjs().format("YYYY-MM-DD");
	localStorage.setItem("l24PopupTodayNo", today);
	popupOpen.value = false;
	initLoad.value = false;
};

const go_link = () => {
	// console.log(wstore.value.livePlayerLink)
	if (wstore.value.livePlayerLink && wstore.value.livePlayerLink != "/live24_player.html") {
		location.href = wstore.value.livePlayerLink;
	} else {
		try {
			location.href = live.value.ui.link_url;
		} catch (e) {}
	}
};

const go_popup = (url) => {
	window.open(url);
};

const live = computed(() => {
	try {
		if (store.liveState.state != 'vod') {
			return store.liveState;
		} else {
			if (wstore.value.privew) {
				return {
					state: wstore.value.privewType ? wstore.value.privewType : "trailer",
					ui: {
						thumb_url:
							"https://nnbkegvqsbcu5297614.cdn.ntruss.com/102017/202206/b5914720211e57b4a13df3362657677d.png",
						subject: "라이브 제목",
						startTime: dayjs().format("YYYY-MM-DD HH:mm"),
					},
				};
			}
		}
	} catch (e) {
		return [];
	}
});

const product = computed(() => {
	try {
		if (store.liveState.state != 'vod') {
			return store.liveState.select_product_list == null ? false : store.liveState.select_product_list[0];
		} else {
			if (wstore.value.privew) {
				return {
					product_image_url:
						"https://nnbkegvqsbcu5297614.cdn.ntruss.com/profile/202401/49f7acbcc9b2c7dc1ed8ac11a14b48fe.png",
					product_name: "상품명",
					price: 10000,
					retail_price: 20000,
					surl: "https://www.naver.com",
				};
			}
		}
	} catch (e) {
		return [];
	}
});

const setOptions = async (options) => {
	let reload = false;
	try {
		if (options.state) {
			if (options.state != wstore.value.state) {
				reload = true;
			}
		}
	} catch (e) {}
	wstore.value = { ...wstore.value, ...options };

	if (reload) {
		await store.getLiveState({
			state: wstore.value.state,
		});
	}
};

defineExpose({
	setOptions,
});

let l24config = null;
onMounted(async () => {
	wstore.value = { ...wstore.value, ...props.options };

	let parser = new UAParser(navigator.userAgent);
	if (parser.getDevice().type === "mobile") {
		isMobile.value = true;
	}
	l24config = await store.getL24State();

	let liveInfo = await store.getLiveState();

	initLoad.value = true;

	if (liveInfo) {
		let today = dayjs().format("YYYY-MM-DD");
		let todayNo = localStorage.getItem("l24PopupTodayNo");

		if (todayNo != today) {
			popupOpen.value = true;
		}
	} else {
		if (wstore.value.privew) {
			popupOpen.value = true;
		}
	}
});
</script>

<template>
	<div
		class="l24PlayerWarpFixed pos_center"
		:class="{
			pos_f: !wstore.privew,
			pos_a: wstore.privew && !wstore.innerMode,
		}"
		:style="{
			zIndex: wstore.zIndex,
			width: wstore.width,
			aspectRatio: wstore.aspectRatio,
		}"
		v-if="initLoad && popupOpen && live"
	>
		<div
			class="l24PopupBody animate__animated animate__fadeIn animate__delay-1s box-shadow-z6"
			:style="{
				background: `url(${live.ui.thumb_url}) center / cover no-repeat`,
				borderRadius: wstore.borderRadius,
			}"
		>
			<div class="l24PopupBodyArea" style="font-size: 14px; line-height: 1.1">
				<div style="display: flex; justify-content: space-between; align-items: center; padding: 1em 1em">
					<div>
						<img
							src="https://nnbkegvqsbcu5297614.cdn.ntruss.com/profile/202401/acc6d34d9322e0ed581f6670990f824b.png"
							alt=""
							v-if="live.state == 'trailer'"
						/>
						<img
							src="https://nnbkegvqsbcu5297614.cdn.ntruss.com/profile/202401/9115e97b5747c7d4c9c8461271a964e0.png"
							alt=""
							v-else
						/>
<!--						<div class="IconEqual"></div>-->
					</div>
					<button
						type="button"
						class="nostyle"
						style="color: #fff; cursor: pointer"
						@click="popupOpen = false"
					>
						<i class="ph ph-x" style="font-size: 24px"></i>
					</button>
				</div>
				<div style="flex: 1; display: flex; justify-content: center; align-items: center; padding: 1em">
					<div style="text-align: center; padding-top: 3em" :class="product.length > 0 ? 'my-4' : ''">
						<div class="el4 text-white">
							<strong class="text-white" style="font-size: 1.4em; text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)">{{
								live.ui.subject
							}}</strong>
						</div>
						<div class="my-3">
							<div class="text-white" v-if="live.state == 'trailer'">
								<strong class="text-white" style="font-size: 1.4em; text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)">
									{{ dayjs(live.ui.startTime).format("MM월 DD일") }}
								</strong>
							</div>

							<div class="text-white" v-if="live.state == 'live'">
								<strong class="text-white" style="font-size: 1.2em; text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)">
									라이브가 시작되었습니다.
								</strong>
							</div>

							<div class="text-white" v-if="live.state == 'trailer'">
								<strong class="text-white" style="font-size: 3em; text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)">
									{{ dayjs(live.ui.startTime).format("HH:mm") }}
								</strong>
							</div>

							<div v-if="live.state == 'trailer'">
								<button type="button" class="goBtn" @click="go_link()">자세히보기</button>
							</div>
							<div v-else>
								<button type="button" class="goBtn" @click="go_link()">라이브 입장하기</button>
							</div>
						</div>
					</div>
				</div>
				<div v-if="product">
					<div style="margin-left: 1.3em; margin-right: 1.3em">
						<div
							style="
								display: flex;
								align-items: center;
								background-color: #ffffff;

								overflow: hidden;
							"
							:style="{
								borderRadius: wstore.borderRadius,
							}"
						>
							<div style="width: 75px; height: 75px">
								<img
									src="https://nnbkegvqsbcu5297614.cdn.ntruss.com/profile/202401/49f7acbcc9b2c7dc1ed8ac11a14b48fe.png"
									:style="{
										width: '100%',
										height: '100%',
										background: `url(${product.product_image_url}) center / cover no-repeat`,
										backgroundColor: '#cdcdcd',
									}"
									alt=""
								/>
							</div>
							<button
								type="button"
								class="nostyle"
								style="flex: 1; padding: 0.8em; color: #000; text-align: left; cursor: pointer"
								@click="go_popup(product.surl)"
							>
								<div
									style="
										margin-bottom: 0.2em;
										font-size: 1em;
										line-height: 1.2em;
										letter-spacing: -1px;
									"
									class="el4"
								>
									{{ product.product_name }}
								</div>
								<div style="margin-top: 5px">
									<strong
										class="r_box"
										style="zoom: 0.7"
										v-if="product.retail_price > 0 && product.retail_price !== product.price"
									>
										{{ Math.abs(Math.round((product.price / product.retail_price) * 100 - 100)) }}%
									</strong>

									<strong class="price"
										>{{ product.price ? parseInt(product.price).toLocaleString() : 0 }}원</strong
									>
								</div>
							</button>
						</div>
					</div>
					<div
						style="
							text-align: right;
							padding: 0.3em;
							margin-top: 1.2em;
							background-color: rgba(255, 255, 255, 0.12);
							display: flex;
							justify-content: end;
						"
					>
						<button type="button" class="nostyle flex items-center" style="color: #fff" @click="todayNo()">
							<i class="ph-x ph"></i> 오늘하루안보기
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.my-3 > * {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}
.my-4 > * {
	margin-top: 1em;
	margin-bottom: 1em;
}
.pos_f {
	position: fixed;
}
.pos_a {
	position: absolute;
}
.nostyle {
	border: none;
	outline: none;
	background: none;
}
.goBtn {
	margin-top: 1.2em;
	background-color: #000;
	color: #fff;
	border: none;
	outline: none;
	padding: 0.7em 1.4em;
	font-size: 1em;
	border-radius: 100px;
}
.el4 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	line-height: 1.7em;
	min-height: 1.5em;
	max-height: 3.2em;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.l24PlayerWarpFixed {
}

.l24PopupBody {
	background-color: #fff;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	color: #fff;
}

.l24PopupBodyArea {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.37);
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.pos_center {
	top: 40%;
	left: 50%;
	-webkit-transform: translate(-40%, -50%);
	transform: translate(-40%, -50%);
}

.r_box {
	background: #ff0000;
	border-radius: 0.4rem;
	color: #fff;
	padding: 0 0.3rem;
	font-size: 1.2em;
	letter-spacing: 0;
	font-weight: 600;
	margin-right: 5px;
	display: inline-block;
	line-height: 1.3;
}
.box-shadow-z6 {
	box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}



.IconEqual {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-right: 2px;
	background-image: url(https://nnbkegvqsbcu5297614.cdn.ntruss.com/profile/202401/b5658b47341e97e00d84ac2e2abeda5f.png);
	background-size: 100% 18000%;
	animation: IconEqual_ani 3s steps(180) infinite;
	vertical-align: top
}

@keyframes IconEqual_ani {
	from {
		background-position: 0 2160px
	}

	to {
		background-position: 0 0
	}
}

.text-white {
	color: #ffffff !important;
}
</style>
