import axios from "axios";
import lscache from "lscache";
lscache.setExpiryMilliseconds(1000);
import dayjs from "dayjs";
import { defineStore } from "pinia";
const timezone = require("dayjs/plugin/timezone");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
dayjs.extend(timezone);

import config from "../src/config";

export const useL24Store = defineStore("l24", {
	state: () => ({
		l24Id: window.l24Id,
		l24Option: window.options,
		options: null,
		config: config,
		l24config: null,
		liveState: null,
		trailer: null,
		vods: null,
		shorts: [],
		settings: null,
		previewShorts: [],
		// liveState:null,
	}),
	getters: {},
	actions: {
		/**
		 * 라이브 배너용 라이브데이터 가져오기
		 * @param store
		 * @param params
		 * @returns {Promise<*>}
		 */
		async getLiveFloatBannerData() {
			let url = config.apis.live.replace("[#L24ID]", window.l24Id);
			let res = await axios.get(url);
			if (res.data.code == 200) {
				let result = res.data.result.total > 0 ? res.data.result.data[0] : null;
				this.liveState = result;
				return result;
			}
		},

		/**
		 * 카페24 전용 , 노출 설정 가져오기
		 * @param store
		 * @param params
		 * @returns {Promise<*>}
		 */
		async getL24Config(cache_time = 5) {
			if (!this.l24config) {
				let url = config.apis.config.replace("[#L24ID]", window.l24Id);
				let res = await axios.get(url, { params: { cache_time: cache_time } });
				if (res.data.code == 200) {
					let result = res.data.result.data ? res.data.result.data[0] : null;
					this.l24config = result;
					return result;
				}
			}
		},
		async getL24ConfigUserNo(user_no, cache_time = 5) {
			if (!this.l24config && user_no) {
				let url = `https://live24.app/api/v3/json/list/config/1/data.json`;
				let res = await axios.get(url, { params: { user_no: user_no, cache_time: cache_time } });
				if (res.data.code == 200) {
					let result = res.data.result.data ? res.data.result.data[0] : null;
					this.l24config = result;
					return result;
				}
			}
		},

		async getL24State() {
			let cache = lscache.get(`state_${window.l24Id}`);
			if (!cache) {
				let url = `https://${window.l24Id}.live24.app/api/v4/widgetState/info`;
				let res = await axios.get(url);
				if (res.data.code == 200) {
					let result = res.data.result;
					lscache.set(`state_${window.l24Id}`, result, 1000 * 30);
					return result;
				}
			} else {
				return cache;
			}
		},

		/**
		 * 유저 위젯 셋팅값 가져오기
		 * @param key
		 * @returns {Promise<void>}
		 */
		async getSettings(options) {
			let url = config.apis.settings.replace("[#L24USERNO]", window.users.user_no);
			let res = await axios.get(url, { headers: { "X-Auth-Key": window.users.user_key } });
			if (res.data.code === 200) {
				if (res.data.result) {
					let result = res.data.result;
					this.settings = result;
					return result;
				} else {
					return;
				}
			}
		},
		/**
		 * 라이브 예고 트레일러 가져오기
		 * @returns {Promise<*>}
		 */
		async getTrailerFloatBanner(params) {
			let url = config.apis.trailer.replace("[#L24ID]", window.l24Id);
			let res = await axios.get(url, { params: params });
			if (res.data.code == 200) {
				if (res.data.result.data) {
					let result = res.data.result.data;
					this.trailer = result;
					return result;
				} else {
					return;
				}
			}
		},

		/**
		 * vod 목록 가져오기
		 * @param params
		 * @returns {Promise<*>}
		 */
		async getVodCardList(params) {
			let url = config.apis.vod.replace("[#L24ID]", window.l24Id);
			if (params.page_no) {
				url += `/${params.page_no}`;
			}
			let res = await axios.get(url, { params: params });

			if (res.data.code == 200) {
				let result = res.data.result.total > 0 ? res.data.result.data : null;
				this.vods = result;
				return result;
			}
		},

		async getShortsList(params) {
			let url = `https://${this.l24Id}.live24.app/api/v3/json/list/shorts/1`;
			let res = await axios.get(url, { params: params });

			if (res.data.code == 200) {
				let result = res.data.result.data;
				this.shorts = result;
				return result;
			}
		},
		async getShortsShowcase(params) {
			let pageNo = params.page_no;
			if (!pageNo) pageNo = 1;

			let url = `https://${this.l24Id}.live24.app/api/v3/json/list/shorts/${pageNo}`;
			let res = await axios.get(url, { params: params });

			if (res.data.code == 200) {
				let result = res.data.result.data;
				this.shorts = result;
				return result;
			}
		},

		async getShortsPreviewList(params) {
			let url = `https://live24.app/shorts24/content/in_card_view`;

			let res = await axios.get(url, { params: params });

			if (res.data.code == 200) {
				this.previewShorts = res.data.result;
			}
		},

		/**
		 * 예고/라이브/VOD 상태 API
		 * @returns {Promise<*>}
		 */
		async getLiveState(params) {
			let url = config.apis.liveState.replace("[#L24ID]", window.l24Id);
			let res = await axios.get(url, {
				// params: params,
				params: {
					state: "no_template",
				},
			});

			if (res.data.code == 200) {
				let result = res.data.result_cnt > 0 ? res.data.result : null;
				this.liveState = result;
				return result;
			}
		},

		async getDomainSetting(params) {
			try {
				const data = await axios.get("https://mapi.live24.app/post/content/restrict", {
					method: "GET",
					params,
				});

				if (data.data.code === 200) {
					return data.data;
				} else {
					return null;
				}
			} catch (error) {
				console.error(error);
			}
		},

		async getUserUnPaidInfo(params) {
			try {
				const data = await axios.get("https://mapi.live24.app/post/content/unpaid_restrict_s24", {
					method: "GET",
					params,
				});

				if (data.data.code === 200) {
					return data.data;
				} else {
					return null;
				}
			} catch (error) {
				console.error(error);
			}
		},

		async setUseLog(params) {
			let url = `https://${this.l24Id}.live24.app/api/v4/WidgetLog/insert`;
			let res = await axios.post(url, {
				...params,
				mall_id: this.l24Id,
			});
			if (res.data.code == 200) {
				return res.data.result;
			}
		},
	},
});
