<template>
	<Transition name="fade">
		<div class="l24">
			<a href="javascript:void(0)" @click="openVod(p)">
				<div class="cardWrap mb-3">
					<div class="badgeArea" style="z-index: 10; zoom: 1.2">
						<span class="badge badge-pill badge-danger">VOD</span>
					</div>

					<div class="pos_r">
						<img
							v-if="p.ui.thumb_url.indexOf('bmsrcyjernvo5150006.cdn.ntruss.com/') <= -1"
							:src="p.ui.thumb_url"
							alt=""
							class="thumb"
							:style="{
								borderRadius: wstore.borderRadius,
								aspectRatio: wstore.aspectRatio,
							}"
							loading="lazy"
						/>
						<div
							v-else
							:style="{
								borderRadius: wstore.borderRadius,
								aspectRatio: wstore.aspectRatio,
							}"
							class="bg-dark"
						>
							<div class="not-thumb w-full">
								<div class="thumb-space">
									<img
										src="https://nnbkegvqsbcu5297614.cdn.ntruss.com/profile/202312/f3172da5c711eaec65ea703cd4682bc3.svg"
										alt=""
										style="width: 100%; height: 100%"
									/>
								</div>
							</div>
						</div>
						<div class="durationBadge">
							{{ p.ui.duration_txt }}
						</div>
					</div>

					<div
						class="p-1"
						style="padding-top: 10px"
						:style="{ fontSize: wstore.subjectFontSize, color: wstore.textColor }"
					>
						<div
							class="el-text2"
							style="font-size: 1em"
							:style="{ maxHeight: `${fontSize * 2.1 + 15}px`, color: wstore.textColor }"
						>
							{{ p.ui.subject }}
						</div>
						<div class="text-muted" style="font-size: 0.85em; margin-top: 5px">
							<span class="mr-2" :style="{ color: wstore.textColor }">
								<i class="ph ph-heart" style="vertical-align: -2px"></i> {{ p.ui.heart }}
							</span>
							<span :style="{ color: wstore.textColor }"
								><i class="ph ph-play" style="vertical-align: -2px"></i> {{ p.ui.view }}</span
							>
						</div>
					</div>
				</div>
			</a>
		</div>
	</Transition>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
const props = defineProps({
	p: {
		type: Object,
		required: true,
	},
	wstore: {
		type: Object,
		default: null,
	},
});

const fontSize = computed(() => {
	let size = props.wstore.subjectFontSize;
	if (size) {
		return size.replaceAll("px", "");
	} else {
		return 16;
	}
});

const openVod = (vod) => {
	let link_url = vod.ui.link_url;

	if (link_url.indexOf("?") > -1) {
		link_url =
			link_url.includes("content_no") > 0
				? link_url + "&nox=1"
				: link_url + "&nox=1&content_no=" + vod.content_no;
	} else {
		link_url =
			link_url.includes("content_no") > 0
				? link_url + "&nox=1"
				: link_url + "?nox=1&content_no=" + vod.content_no;
	}
	console.log("!!", link_url);
	window.open(link_url, "yesd", "width=420,height=800");
};
</script>

<style scoped>
.l24 a {
	text-decoration: none;
	color: #000;
	font-size: 16px;
}
.cardWrap {
	width: 100%;
	height: 100%;
	position: relative;
}

.badgeArea {
	position: absolute;
	padding: 5px 9px;
}

.thumb {
	object-fit: cover;
	width: 100%;
	aspect-ratio: 9/12;
	background-color: #e1e1e1;
}

.el-text2 {
	line-height: 150% !important;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-height: 44px;

	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.durationBadge {
	position: absolute;
	bottom: 15px;
	right: 5px;
	background-color: rgba(0, 0, 0, 0.72);
	color: #fff;
	padding: 3px 8px 3px 8px;
	border-radius: 10px;
	font-size: 12px;
}
.text-muted {
	font-size: 12px;
	opacity: 0.7;
}
.pos_r {
	position: relative;
}
.pos_a {
	position: absolute;
}

.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.badge {
		transition: none;
	}
}
a.badge:hover,
a.badge:focus {
	text-decoration: none;
}
.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.badge-pill {
	padding-right: 0.5em;
	padding-left: 0.5em;

	border-radius: 10rem;
}

.badge-primary {
	color: #212529;
	background-color: #fb9678;
}
a.badge-primary:hover,
a.badge-primary:focus {
	color: #212529;
	background-color: #fa6f46;
}
a.badge-primary:focus,
a.badge-primary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(251, 150, 120, 0.5);
}

.badge-secondary {
	color: #212529;
	background-color: #f8f9fa;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
	color: #212529;
	background-color: #dae0e5;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-success {
	color: #fff;
	background-color: #00c292;
}
a.badge-success:hover,
a.badge-success:focus {
	color: #fff;
	background-color: #008f6c;
}
a.badge-success:focus,
a.badge-success.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 194, 146, 0.5);
}

.badge-info {
	color: #fff;
	background-color: #03a9f3;
}
a.badge-info:hover,
a.badge-info:focus {
	color: #fff;
	background-color: #0286c1;
}
a.badge-info:focus,
a.badge-info.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(3, 169, 243, 0.5);
}

.badge-warning {
	color: #212529;
	background-color: #fec107;
}
a.badge-warning:hover,
a.badge-warning:focus {
	color: #212529;
	background-color: #d19e01;
}
a.badge-warning:focus,
a.badge-warning.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(254, 193, 7, 0.5);
}

.badge-danger {
	color: #fff;
	background-color: #ff0538;
}
a.badge-danger:hover,
a.badge-danger:focus {
	color: #fff;
	background-color: #dc3f4e;
}
a.badge-danger:focus,
a.badge-danger.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(228, 106, 118, 0.5);
}

.badge-light {
	color: #212529;
	background-color: #f8f9fa;
}
a.badge-light:hover,
a.badge-light:focus {
	color: #212529;
	background-color: #dae0e5;
}
a.badge-light:focus,
a.badge-light.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
	color: #fff;
	background-color: #343a40;
}
a.badge-dark:hover,
a.badge-dark:focus {
	color: #fff;
	background-color: #1d2124;
}
a.badge-dark:focus,
a.badge-dark.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-cyan {
	color: #fff;
	background-color: #01c0c8;
}
a.badge-cyan:hover,
a.badge-cyan:focus {
	color: #fff;
	background-color: #018f95;
}
a.badge-cyan:focus,
a.badge-cyan.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(1, 192, 200, 0.5);
}

.badge-purple {
	color: #212529;
	background-color: #ab8ce4;
}
a.badge-purple:hover,
a.badge-purple:focus {
	color: #212529;
	background-color: #8d63da;
}
a.badge-purple:focus,
a.badge-purple.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.5);
}

.not-thumb {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.bg-dark {
	width: 100%;
	height: 100%;
	background-color: #000000;
	text-align: center;
}
.thumb-space {
	width: 50px;
	height: 50px;
	margin: 0 auto;
}
</style>
