<template>
	<Transition name="fade">
		<div
			:style="{
				paddingTop: wstore.marginTop,
				paddingBottom: wstore.marginBottom,
				paddingLeft: wstore.marginLeft,
				paddingRight: wstore.marginRight,
			}"
			class="w-full mx-auto"
		>
			<div
				v-if="init_load && imgUrl"
				class="w-full mx-auto"
				:style="{
					backgroundColor: backgroundColor,
					color: textColor,
					maxWidth: wstore.maxWidth,
					maxHeight: wstore.maxHeight,
				}"
			>
				<a :href="wstore.href" :target="wstore.target" v-if="wstore.href">
					<img :src="imgUrl" class="l24-images" :style="{ maxHeight: wstore.maxHeight }" />
				</a>
				<img :src="imgUrl" class="l24-images" :style="{ maxHeight: wstore.maxHeight }" v-else />
			</div>
		</div>
	</Transition>
</template>

<script setup>
import { ref, onMounted, computed, defineProps } from "vue";
import { useL24Store } from "../store/l24";
const store = useL24Store();

const props = defineProps({
	options: {
		type: Object,
	},
});

//data()
const wstore = ref({
	marginTop: "0",
	marginBottom: "0",
	marginLeft: "0",
	marginRight: "0",
	image: "https://nnbkegvqsbcu5297614.cdn.ntruss.com/profile/202311/56e2220e54e87603798b5525ead5cf43.svg",
	backgroundColor: "#847f95",
	textColor: "#000000",
	maxHeight: "100px",
	maxWidth: "980px",
	href: "",
	target: "_blank",
});

let backgroundColor = computed(() => {
	if (wstore.value.backgroundColor) {
		return wstore.value.backgroundColor;
	} else {
		return "transparent";
	}
});
let textColor = computed(() => {
	if (wstore.value.textColor) {
		return wstore.value.textColor;
	} else {
		return "#000000";
	}
});

const imgUrl = computed(() => {
	try {
		return wstore.value.image
			? wstore.value.image
			: "https://nnbkegvqsbcu5297614.cdn.ntruss.com/profile/202311/56e2220e54e87603798b5525ead5cf43.svg";
	} catch (e) {}
});

const init_load = ref(false);

const run = () => {
	init_load.value = true;
};

const setOptions = (options) => {
	wstore.value = { ...wstore.value, ...options };
};

defineExpose({
	setOptions,
});

//mounted()
onMounted(async () => {
	//계정 환경설정 로드
	let l24config = await store.getL24Config();

	wstore.value = { ...wstore.value, ...props.options };

	run();
});
</script>

<style scoped>
*,
*:before,
*:after {
	box-sizing: border-box;
	font-family: "Noto Sans KR", sans-serif;
	line-height: 1.5;
	font-size: 14px;
}
.l24-images {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.w-full {
	width: 100%;
}
.mx-auto {
	margin: 0 auto;
}
</style>
