<template>
	<Transition name="fade">
		<div class="l24">
			<a href="javascript:void(0)" @click="openVod(p)">
				<div class="cardWrap mb-3">
					<div class="badgeArea" style="z-index: 10; zoom: 1.2">
						<div
							class="badge badge-pill badge-danger"
							style="
								background-color: #4b52f9;
								display: flex;
								align-items: center;
								gap: 3px;
								padding-left: 6px;
								padding-right: 9px;
							"
						>
							<i class="ph-fill ph-play" style="font-size: 10px" /> Shorts
						</div>
					</div>

					<div v-if="!props.preview" class="pos_r">
						<img
							v-if="p.ui.thumb_url.indexOf('bmsrcyjernvo5150006.cdn.ntruss.com/') <= -1"
							:src="p.ui.thumb_url"
							alt=""
							class="thumb"
							:style="{
								borderRadius: wstore.borderRadius,
								aspectRatio: wstore.aspectRatio,
							}"
							loading="lazy"
						/>
						<div
							v-else
							:style="{
								borderRadius: wstore.borderRadius,
								aspectRatio: wstore.aspectRatio,
							}"
							class="bg-dark"
							style="user-select: none; -webkit-user-select: none"
						>
							<div class="not-thumb w-full">
								<div class="thumb-space">
									<img
										src="https://nnbkegvqsbcu5297614.cdn.ntruss.com/profile/202312/f3172da5c711eaec65ea703cd4682bc3.svg"
										alt=""
										style="width: 100%; height: 100%"
									/>
								</div>
							</div>
						</div>
						<!--						<div class="durationBadge"></div>-->
					</div>
					<div v-else class="pos_r">
						<video
							autoplay
							loop
							muted
							playsinline
							:style="{
								width: '100vw',
								objectFit: 'cover',
								maxWidth: '100%',
								borderRadius: wstore.borderRadius,
								aspectRatio: wstore.aspectRatio,
							}"
							style="user-select: none; -webkit-user-select: none"
						>
							<source :src="props.preview.endpointurl" type="video/mp4" />
						</video>
					</div>

					<div
						class=""
						:style="{
							fontSize: wstore.subjectFontSize,
							color: wstore.textColor,
							paddingTop: `${fontSize / 2.5}px`,
						}"
					>
						<div
							:style="{
								minHeight: wstore.product == 'Y' && wstore.subject == 'Y' ? `${fontSize * 3}px` : '',
							}"
						>
							<div
								v-if="!wstore.subject || wstore.subject == 'Y'"
								class="el-text2"
								:style="{
									maxHeight: `${fontSize * 3}px`,
									color: wstore.textColor,
									fontSize: `${fontSize}px`,
								}"
							>
								{{ p.ui.subject }}
							</div>
						</div>
						<div v-if="p.select_product_list" style="margin-top: 0.5rem; line-height: 150% !important">
							<div
								v-for="item in p.select_product_list"
								style="display: flex; align-items: center; gap: 0.5rem"
							>
								<img
									v-if="wstore.productType.includes('image')"
									:src="item.product_image_url"
									:alt="item.name"
									style="width: 40px; height: 40px; border-radius: 5px"
								/>
								<div>
									<div
										:style="{ fontSize: wstore.titleFontSize }"
										v-if="wstore.productType.includes('name')"
									>
										<span
											:style="{ fontSize: wstore.titleFontSize }"
											class="el-text"
											v-html="item.product_name"
										></span>
									</div>
									<div
										style="display: flex; align-items: center; gap: 0.3rem"
										v-if="
											wstore.productType.includes('sale') || wstore.productType.includes('price')
										"
									>
										<!--										<span v-if="item.retail_price != 0 && wstore.productType.includes('sale')" style="color: white; background-color: red; border-radius: 5px; padding: 1px 4px; font-size: 12px;">{{Math.ceil((100 - (item.price / item.retail_price) * 100))}}%</span>-->
										<span
											v-if="item.retail_price != 0 && wstore.productType.includes('sale')"
											style="
												color: white;
												background-color: red;
												border-radius: 5px;
												padding: 1px 4px;
												font-size: 12px;
											"
											>{{
												Math.ceil(((item.retail_price - item.price) / item.retail_price) * 100)
											}}%</span
										>
										<span
											:style="{ fontSize: wstore.titleFontSize }"
											v-if="wstore.productType.includes('price')"
										>
											<span
												:style="{ fontSize: wstore.titleFontSize }"
												style="font-weight: 700"
												>{{ parseInt(item.price).toLocaleString() }}</span
											>원
										</span>
									</div>
								</div>
							</div>
						</div>
						<!--						<div
							:style="{
								color: wstore.textColor,
								fontSize: `${fontSize - 2}px`,
							}"
							style="opacity: 0.7; margin-top: 10px; display: flex; align-items: center"
						>
							<div style="font-weight: 500"><i class="ph ph-play" /> {{ p.ui.view }}</div>
						</div>-->
					</div>
				</div>
			</a>
		</div>
	</Transition>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
const props = defineProps({
	p: {
		type: Object,
		required: true,
	},
	wstore: {
		type: Object,
		default: null,
	},
	userNo: {
		type: Number,
		required: true,
	},
	userType: {
		type: String,
		required: true,
	},
	preview: {
		type: Array,
		default: null,
	},
	product: {
		type: Boolean,
		default: false,
	},
});

const fontSize = computed(() => {
	let size = props.wstore.subjectFontSize;
	if (size) {
		return size.replaceAll("px", "");
	} else {
		return 16;
	}
});

const openVod = (vod) => {
	if (props.wstore.preview) {
		return;
	}
	let link_url = `https://player-v4.live24.app/player/${props.userNo}`;

	if (props.userType == "shop_adm" && self === top && !props.wstore.privew) {
		link_url = `/live24_player.html`;
		if (link_url.indexOf("?") > -1) {
			link_url = link_url + "&content_no=" + vod.content_no + "&type=shorts";
		} else {
			link_url = link_url + "?&content_no=" + vod.content_no + "&type=shorts";
		}

		location.href = link_url;
	}
	if (props.userType == "shorts" && self === top && !props.wstore.privew) {
		link_url = `/live24_player.html`;
		if (link_url.indexOf("?") > -1) {
			link_url = link_url + "&content_no=" + props.p.content_no + "&type=shorts";
		} else {
			link_url = link_url + "?&content_no=" + props.p.content_no + "&type=shorts";
		}

		location.href = link_url.replaceAll("/live24_player.html", "/shorts24_player.html");
	} else {
		if (link_url.indexOf("?") > -1) {
			link_url = link_url + "&nox=1&content_no=" + vod.content_no + "&type=shorts";
		} else {
			link_url = link_url + "?nox=1&content_no=" + vod.content_no + "&type=shorts";
		}
		console.log("!!", link_url);
		window.open(link_url, "yesd", "width=420,height=800");
	}
};
</script>

<style scoped>
.l24 a {
	text-decoration: none;
	color: #000;
	font-size: 16px;
}
.cardWrap {
	width: 100%;
	height: 100%;
	position: relative;
}

.badgeArea {
	position: absolute;
	padding: 9px 9px;
	font-family: "Pretendard", sans-serif;
}

.thumb {
	object-fit: cover;
	width: 100%;
	aspect-ratio: 9/12;
	background-color: #e1e1e1;
}

.el-text {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-height: 44px;

	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.el-text2 {
	line-height: 150% !important;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-height: 44px;

	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.durationBadge {
	position: absolute;
	bottom: 15px;
	right: 10px;
	background-color: rgba(0, 0, 0, 0.72);
	color: #fff;
	padding: 3px 10px 3px 10px;
	border-radius: 10px;
	font-size: 12px;
	font-family: "Pretendard", sans-serif;
}
.text-muted {
	font-size: 12px;
	opacity: 0.7;
}
.pos_r {
	position: relative;
}
.pos_a {
	position: absolute;
}

.badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.badge {
		transition: none;
	}
}
a.badge:hover,
a.badge:focus {
	text-decoration: none;
}
.badge:empty {
	display: none;
}

.btn .badge {
	position: relative;
	top: -1px;
}

.badge-pill {
	padding-right: 0.5em;
	padding-left: 0.5em;

	border-radius: 10rem;
}

.badge-primary {
	color: #212529;
	background-color: #fb9678;
}
a.badge-primary:hover,
a.badge-primary:focus {
	color: #212529;
	background-color: #fa6f46;
}
a.badge-primary:focus,
a.badge-primary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(251, 150, 120, 0.5);
}

.badge-secondary {
	color: #212529;
	background-color: #f8f9fa;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
	color: #212529;
	background-color: #dae0e5;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-success {
	color: #fff;
	background-color: #00c292;
}
a.badge-success:hover,
a.badge-success:focus {
	color: #fff;
	background-color: #008f6c;
}
a.badge-success:focus,
a.badge-success.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 194, 146, 0.5);
}

.badge-info {
	color: #fff;
	background-color: #03a9f3;
}
a.badge-info:hover,
a.badge-info:focus {
	color: #fff;
	background-color: #0286c1;
}
a.badge-info:focus,
a.badge-info.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(3, 169, 243, 0.5);
}

.badge-warning {
	color: #212529;
	background-color: #fec107;
}
a.badge-warning:hover,
a.badge-warning:focus {
	color: #212529;
	background-color: #d19e01;
}
a.badge-warning:focus,
a.badge-warning.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(254, 193, 7, 0.5);
}

.badge-danger {
	color: #fff;
	background-color: #ff0538;
}
a.badge-danger:hover,
a.badge-danger:focus {
	color: #fff;
	background-color: #dc3f4e;
}
a.badge-danger:focus,
a.badge-danger.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(228, 106, 118, 0.5);
}

.badge-light {
	color: #212529;
	background-color: #f8f9fa;
}
a.badge-light:hover,
a.badge-light:focus {
	color: #212529;
	background-color: #dae0e5;
}
a.badge-light:focus,
a.badge-light.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
	color: #fff;
	background-color: #343a40;
}
a.badge-dark:hover,
a.badge-dark:focus {
	color: #fff;
	background-color: #1d2124;
}
a.badge-dark:focus,
a.badge-dark.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-cyan {
	color: #fff;
	background-color: #01c0c8;
}
a.badge-cyan:hover,
a.badge-cyan:focus {
	color: #fff;
	background-color: #018f95;
}
a.badge-cyan:focus,
a.badge-cyan.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(1, 192, 200, 0.5);
}

.badge-purple {
	color: #212529;
	background-color: #ab8ce4;
}
a.badge-purple:hover,
a.badge-purple:focus {
	color: #212529;
	background-color: #8d63da;
}
a.badge-purple:focus,
a.badge-purple.focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.5);
}

.not-thumb {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.bg-dark {
	width: 100%;
	height: 100%;
	background-color: #000000;
	text-align: center;
}
.thumb-space {
	width: 50px;
	height: 50px;
	margin: 0 auto;
}
</style>
