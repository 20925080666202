<script setup>
import _ from "lodash";
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import microW from "../components/ui/micro.vue";
import UiHls from "../components/ui/hls.vue";
import { ref, computed, onMounted, markRaw, onUnmounted, watch, defineProps } from "vue";
import dayjs from "dayjs";
import "dayjs/locale/ko"; //한국어
dayjs.locale("ko");
import lscache from "lscache";
lscache.setExpiryMilliseconds(1000);

import { useL24Store } from "../store/l24";
const store = useL24Store();
const props = defineProps({
	options: {
		type: Object,
	},
});

let startTimer = null;
const initLoad = ref(false);
const initLoad2 = ref(false);
const badgeActive = ref(false);
const isPopup = ref(false);
const isTooltip = ref(false);
const initPopup = ref(false);
const initTooltip = ref(false);
const tarName = ref("box-slide-left");

const wstore = ref({
	marginTop: "0px",
	marginLeft: "0",
	marginBottom: "30px",
	marginRight: "30px",
	maxWidth: "980px",
	title: "",
	titleFontSize: "14px",
	cardGap: "10px",
	backgroundColor: "#ffffff",
	textColor: "#000000",
	mobile: false,
	aspectRatio: "1/1",
	moreText: "더보기",
	moreFontSize: "24px",
	moreLink: "",
	borderRadius: "20px",
	badgeImage: "https://tkmenfxu2702.edge.naverncp.com/profile/202404/17fe27b1bca5bac1d8bc80647b03afdd.svg",
	zIndex: 999,
	useWidget: "Y",
	tryPopup: "N",
	livePreview: false,
	preview: false,
	targetObj: "",
});

const config = computed(() => {
	let defCf = {
		view_live: true,
		view_tr: true,
		zindex: 9999,
	};
	if (store.l24config) {
		return store.l24config;
	} else {
		return defCf;
	}
});

const mouseOver = _.debounce(() => {
	if (initLoad.value) {
		tarName.value = "box-slide-left";
		badgeActive.value = true;
	}
	initLoad2.value = false;
	clearTimeout(startTimer);
}, 200);

const mouseOut = _.debounce(() => {
	if (initLoad.value) {
		tarName.value = "box-slide-right";
		badgeActive.value = false;
	}
	initLoad2.value = false;
	clearTimeout(startTimer);
}, 300);

const popupOpen = () => {
	let delay = 0;
	if (isTooltip.value) {
		delay = 400;
	}
	isTooltip.value = false;
	_.delay(() => {
		tarName.value = "box-slide-right";
		badgeActive.value = false;
		isPopup.value = true;
		initPopup.value = true;

		document.querySelectorAll(".wheelPraStop").forEach(function (el) {
			el.addEventListener(
				"wheel",
				function (e) {
					e.preventDefault();
					e.stopPropagation();
				},
				{ passive: false }
			);
		});
	}, delay);
};

const popupClose = () => {
	isPopup.value = false;
};

let db = null;
let unsubscribe = null;
const PS = ref([]);
const fbInit = async (userNo) => {
	if (config.type == "shop_adm") {
		if (!config.value.view_live && !config.value.view_tr) {
			return;
		}
	}
	if (userNo) {
		db = getFirestore();
		const docRef = doc(db, "post_setup", String(userNo));

		let templateTrInfo = {
			no: 1,
			is_dryrun: "N",
			subject: "LIVE24 예제용 템플릿",
			onair_start: dayjs().add(2, "day").format("YYYY-MM-DD 11:00:00"),
			thumb_url: "https://tkmenfxu2702.edge.naverncp.com/102017/202404/31b1442300aa4d0707f58ce76e573a07.png",
			liveThumbUrl:
				"https://xtuzshll2380.edge.naverncp.com/shorts/11122/encoding_ready_5029212_i6CnIyR5W8_01.jpg",
			livePlayUrl:
				"https://r5ellyzv2643.edge.naverncp.com/hls/kaZwKII2pj5uZ~7qA1QDUNKLUN6xZ5YXJMhvFyqSH5s_/shorts/11122/encoding_ready_5029212_i6CnIyR5W8_shorts,720,p.mp4.smil/master.m3u8",
			content: "이 템플릿의 내용은 알림위젯 미리보기용입니다. 실제 방송이 아님을 알려드립니다.",
		};

		if (wstore.value.mallType == "cafe24") {
			let doc = await getDoc(docRef);
			let result = doc.data();

			if (wstore.value.preview || wstore.value.livePreview) {
				result.tr_no = 1;
				result.tr_info = templateTrInfo;
			}

			if (wstore.value.livePreview) {
				result.post_type = "live";
				result.subject = result.tr_info.subject;
				result.thumb_url = templateTrInfo.liveThumbUrl;
				result.play_url = templateTrInfo.livePlayUrl;
			}

			PS.value = result;

			if (PS.value?.post_type == "live") {
				if (PS?.value.alert_config.view_live) {
					if (config.value.view_live) {
						isPopupOpen.value = true;
					}
				} else {
					isPopupOpen.value = false;
				}
			} else {
				if (PS?.value.alert_config.view_tr) {
					if (PSList?.value?.onair_start > dayjs().format("YYYY-MM-DD HH:mm:ss")) {
						if (config.value.view_tr) {
							isPopupOpen.value = true;
						} else if (PSList?.value.onair_start > dayjs().format("YYYY-MM-DD HH:mm:ss")) {
							isPopupOpen.value = true;
						}
					} else {
						if (config.value.view_tr) {
							isPopupOpen.value = true;
						}
					}
				} else {
					isPopupOpen.value = false;
				}
			}

			try {
				wstore.value = { ...wstore.value, ...PS.value.l24Cloud.widget, ...props.options };
			} catch (e) {
				wstore.value = { ...wstore.value, ...props.options };
			}
		} else {
			unsubscribe = await onSnapshot(docRef, (doc) => {
				let result = doc.data();

				if (wstore.value.preview || wstore.value.livePreview) {
					result.tr_no = 1;
					result.tr_info = templateTrInfo;
				}

				if (wstore.value.livePreview) {
					result.post_type = "live";
					result.subject = result.tr_info.subject;
					result.thumb_url = templateTrInfo.liveThumbUrl;
					result.play_url = templateTrInfo.livePlayUrl;
				}

				PS.value = result;

				if (PS.value?.post_type == "live") {
					// if (PS?.value.alert_config.view_live) {
					// 	if (config.value.view_live) {
					// 		isPopupOpen.value = true;
					// 	}
					// } else {
						isPopupOpen.value = true;
					// }
				} else {
					if (PSList?.value.onair_start > dayjs().format("YYYY-MM-DD HH:mm:ss")) {
						isPopupOpen.value = true;
					}
					// if (PS?.value.alert_config.view_tr) {
					// 	if (PSList?.value.onair_start > dayjs().format("YYYY-MM-DD HH:mm:ss")) {
					// 		if (config.value.view_tr) {
					// 			isPopupOpen.value = true;
					// 		} else if (PSList?.value.onair_start > dayjs().format("YYYY-MM-DD HH:mm:ss")) {
					// 			isPopupOpen.value = true;
					// 		}
					// 	} else {
					// 		if (config.value.view_tr) {
					// 			isPopupOpen.value = true;
					// 		}
					// 	}
					// } else {
					// 	isPopupOpen.value = false;
					// }`
				}

				// if (PS.value?.post_type == "live") {
				// 	isPopupOpen.value = true;
				// } else {
				// 	if (PSList?.value.onair_start > dayjs().format("YYYY-MM-DD HH:mm:ss")) {
				// 		isPopupOpen.value = true;
				// 	}
				// }

				try {
					wstore.value = { ...wstore.value, ...PS.value.l24Cloud.widget, ...props.options };
				} catch (e) {
					wstore.value = { ...wstore.value, ...props.options };
				}
			});
		}
	}
};

const setOptions = (options) => {
	let reload = false;
	wstore.value = { ...wstore.value, ...options };
};

const dateTxt1 = computed(() => {
	return PSList?.value.onair_start ? dayjs(PSList?.value.onair_start).format("YYYY년 MM월 DD일") : null;
});
const dateTxt11 = computed(() => {
	return PSList?.value.onair_start ? dayjs(PSList?.value.onair_start).format("MM월 DD일") : null;
});

const dateTxtBan = computed(() => {
	return PSList?.value.onair_start ? (dayjs(PSList?.value.onair_start).format("HH") >= 12 ? "오후" : "오전") : null;
});

const dateTxtYo = computed(() => {
	return PSList?.value.onair_start ? dayjs(PSList?.value.onair_start).format("dd") : null;
});

const dateTxt2 = computed(() => {
	return PSList?.value.onair_start ? dayjs(PSList?.value.onair_start).format("hh:mm") : null;
});

const isPopupOpen = ref(false);
const isPopupOpenSub = ref(false);

watch(
	() => isPopupOpen.value,
	(to) => {
		_.delay(() => {
			isPopupOpenSub.value = to;
		}, 300);
	}
);
const l24OneDayClose = () => {
	if (wstore.value.preview || wstore.value.livePreview) {
		alert("미리보기에서는 동작하지 않습니다.");
		return;
	}
	lscache.set("l24OneDayClose", dayjs().format("YYYYMMDD"), 60 * 60 * 24);
	isPopupOpen.value = false;
};

const isPopupRun = computed(() => {
	if (!lscache.get("l24OneDayClose") && isPopupOpen.value) {
		return true;
	}
	if (lscache.get("l24OneDayClose") < dayjs().format("YYYYMMDD") && isPopupOpen.value) {
		return true;
	}
});

const isPopupRunMore = computed(() => {
	if (!lscache.get("l24OneDayClose")) {
		return true;
	}
	if (lscache.get("l24OneDayClose") < dayjs().format("YYYYMMDD")) {
		return true;
	}
});

defineExpose({
	setOptions,
	popupOpen,
	popupClose,
});

watch(
	() => PS.value,
	(to, from) => {
		if (to.mall_id && !from.mall_id) {
			initLoad.value = true;
			setTimeout(() => {
				initLoad2.value = true;
				isTooltip.value = true;

				startTimer = setTimeout(() => {
					tarName.value = "box-slide-right";
					initLoad2.value = false;
				}, 3000);

				//popupOpen();
			}, 1000);
		}
	}
);

const playerUrl = computed(() => {
	if (wstore.value.mallType == "cafe24") {
		return "/live24_player.html";
	}
	try {
		if (PS.value?.l24Cloud?.installCheck) {
			let domainw = PS.value?.l24Cloud?.domain?.pc;
			if (domainw == top.location.origin) {
				domainw = "";
			}

			if (PS.value?.l24Cloud?.installUrl.substring(0, 1) == "/") {
				return domainw + PS.value?.l24Cloud?.installUrl;
			} else {
				return domainw + "/" + PS.value?.l24Cloud?.installUrl;
			}
		} else {
			return "";
		}
	} catch (e) {
		return "";
	}
});

const goContent = () => {
	if (wstore.value.livePreview || wstore.value.preview) {
		alert(`미리보기에서는 동작하지 않습니다.`);
		return;
	}
	if (wstore.value.useWidget == "Y") {
		isPopupOpen.value = false;
		popupOpen();
	} else {
		if (!playerUrl.value) {
			alert(`LIVE24 플레이어가 감지되지 않습니다.`);
			return;
		}

		let andTag = playerUrl.value.indexOf("?") > -1 ? "&" : "?";

		if (PS.value.tr_no > 1) {
			if (PS.value?.post_type == "live") {
				isPopupOpen.value = false;
				location.href = playerUrl.value + `${andTag}tr_no=` + PSList.value.no;
			} else {
				isPopupOpen.value = false;
				window.open(playerUrl.value + `${andTag}tr_no=` + PSList.value.no);
			}
		}
	}
};

const isClickIng = ref(false);
const mouseDown = () => {
	isClickIng.value = true;
};
const mouseUp = () => {
	isClickIng.value = false;
};

const PSList = computed(() => {
	if (PS?.value.tr_info?.is_dryrun == "N" && PS?.value.tr_no != 1) {
		if (PS?.value.tr_list[0]?.no != PS?.value.tr_info.no && PS?.value.post_type != "live") {
			if (_.filter(PS?.value.tr_list, { no: String(PS?.value.tr_info.no) }).length > 0) {
				return PS?.value.tr_info;
			} else {
				return PS?.value.tr_list[0];
			}
		} else {
			return PS?.value.tr_info;
		}
	} else {
		if (wstore.value.isLivePreview || wstore.value.preview) {
			return PS?.value.tr_info;
		} else {
			return PS.value.tr_list[0];
		}
	}
});

//mounted()
onMounted(async () => {
	//계정 환경설정 로드
	if (window.l24UserNo) {
		await store.getL24ConfigUserNo(window.l24UserNo, 10);
		wstore.value = { ...wstore.value, ...props.options };

		setTimeout(async () => {
			if (window.l24UserNo) {
				await fbInit(window.l24UserNo);
			}
		}, 100);
	}
});
</script>

<template>
	<!-- 팝업 -->
	<Transition name="fade" mode="out-in">
		<div
			v-if="wstore.tryPopup == 'Y' && isPopupRun && PSList && PSList?.no !== PS?.last_tr_no && !isPopup"
			class="livePopupArea"
			:style="{
				position: wstore.targetObj ? 'absolute' : 'fixed',
				zIndex: config.zindex,
			}"
			style="font-family: 'pretandard', 'Noto Sans KR', sans-serif !important"
		>
			<!--		<div-->
			<!--			v-if="-->
			<!--				wstore.tryPopup == 'Y' &&-->
			<!--				isPopupRun &&-->
			<!--				PS?.tr_info &&-->
			<!--				PS?.tr_no == String(PS?.tr_info?.no) &&-->
			<!--				PS?.tr_info?.is_dryrun == 'N' &&-->
			<!--				PS?.last_tr_no != PS?.tr_no &&-->
			<!--				!isPopup-->
			<!--			"-->
			<!--			class="livePopupArea"-->
			<!--			:style="{-->
			<!--				position: wstore.targetObj ? 'absolute' : 'fixed',-->
			<!--				zIndex: config.zindex,-->
			<!--			}"-->
			<!--			style="font-family: 'pretandard', 'Noto Sans KR', sans-serif !important"-->
			<!--		>-->
			<Transition name="box-slide-up" mode="out-in">
				<div class="livePopupItem drop-shadow-2xl" v-if="isPopupOpenSub">
					<div v-if="PS?.post_type == 'live' && PSList?.no == PS?.tr_no" class="livePopupSet">
						<div class="livePopupSetAreaSub">
							<div class="livePopupThumbBody">
								<div style="width: 100%" class="popupHlsAsp">
									<!--									<UiHls-->
									<!--										v-if="wstore.mallType != 'cafe24'"-->
									<!--										:hlsUrl="-->
									<!--											wstore.livePreview-->
									<!--												? PS.play_url-->
									<!--												: `https://zoipt8ss2701.edge.naverncp.com/live/video/${PS.ui.channel_id}/360p-9-16-2/playlist.m3u8`-->
									<!--										"-->
									<!--										:autoplay="true"-->
									<!--										:muted="true"-->
									<!--										:loop="true"-->
									<!--										:poster="PS.thumb_url"-->
									<!--									/>-->
									<div>
										<img
											src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/970284d9e308573d85e1bcccbac55f67.png"
											:style="{
												background: `url(${PS?.tr_info?.thumb_url}) top center / cover no-repeat`,
											}"
											style="object-fit: cover; width: 100%"
											alt=""
											class="popupThumbAsp"
										/>
									</div>
								</div>
							</div>
							<div class="livePopupSetAreaTxt">
								<div class="tstitle">
									{{ PS?.subject }}
								</div>
								<div class="tsdate live" @click="goContent" style="cursor: pointer">
									<div class="IconEqual" style="zoom: 1.2"></div>
									<div style="color: #fff">라이브가 진행중입니다.</div>
									️
								</div>
							</div>

							<div style="position: absolute; top: 15px; left: 15px" class="livePopupThumbBadeBody">
								<div class="liveBadge">
									<div style="font-weight: 600; font-size: 14px; color: #fff">LIVE</div>
								</div>
							</div>

							<div style="position: absolute; top: 15px; right: 15px" class="livePopupThumbCloseBody">
								<button
									type="button"
									@click="isPopupOpen = false"
									class="nostyleBtn"
									style="
										background-color: #fff;
										border-radius: 30px;
										width: 30px;
										height: 30px;
										display: flex;
										justify-content: center;
										align-items: center;
										border: 1px solid #ececec;
									"
								>
									<img
										src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/6fd67d9417b4f3422ccd27af5ea99611.svg"
										alt=""
										style="width: 20px; height: 20px"
									/>
								</button>
							</div>
						</div>
					</div>
					<div v-else class="livePopupSet">
						<div class="livePopupSetArea">
							<div class="livePopupSetAreaSub">
								<div class="livePopupThumbBody">
									<img
										src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/970284d9e308573d85e1bcccbac55f67.png"
										:style="{
											background: `url(${PSList?.thumb_url}) top center / cover no-repeat`,
										}"
										style="object-fit: cover; width: 100%"
										alt=""
										class="popupThumbAsp"
									/>
								</div>

								<div class="livePopupSetAreaTxt">
									<div class="tstitle">
										{{ PSList?.subject }}
									</div>
									<div class="tsdate flex items-center" @click="goContent" style="cursor: pointer">
										<div style="margin-right: 5px">
											<img
												src="https://tkmenfxu2702.edge.naverncp.com/profile/202406/7079223a895de6615a6cf6708ffa7a33.png"
												alt=""
												style="width: 20px; height: 20px"
											/>
										</div>
										<div style="margin-right: 5px">{{ dateTxt1 }} ({{ dateTxtYo }})</div>
										<div>{{ dateTxtBan }}</div>
										<div>{{ dateTxt2 }}</div>
										️
									</div>
								</div>

								<div style="position: absolute; top: 15px; left: 15px" class="livePopupThumbBadeBody">
									<div class="flex">
										<div class="flex-1 pointer" @click="popupOpen">
											<div class="flex items-center">
												<div class="liveBadge">
													<div>LIVE 예정</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div style="position: absolute; top: 15px; right: 15px" class="livePopupThumbCloseBody">
									<button
										type="button"
										@click="isPopupOpen = false"
										class="nostyleBtn"
										style="
											background-color: #fff;
											border-radius: 30px;
											width: 30px;
											height: 30px;
											display: flex;
											justify-content: center;
											align-items: center;
											border: 1px solid #ececec;
										"
									>
										<img
											src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/6fd67d9417b4f3422ccd27af5ea99611.svg"
											alt=""
											style="width: 20px; height: 20px"
										/>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="livePopupClose">
						<div style="border-right: 1px solid #f3f3f3">
							<button type="button" class="nostyleBtn" style="color: #000" @click="l24OneDayClose">
								오늘 하루 안보기
							</button>
						</div>
						<div
							style="border-right: 1px solid #f3f3f3"
							v-if="PS?.post_type == 'live' && PSList?.no == PS.tr_no"
						>
							<button type="button" class="nostyleBtn" style="color: #000" @click="goContent">
								라이브 참여
							</button>
						</div>
						<div style="border-right: 1px solid #f3f3f3" v-else>
							<button type="button" class="nostyleBtn" style="color: #000" @click="goContent">
								자세히보기
							</button>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	</Transition>
	<!-- 팝업 -->
	<div
		v-show="wstore.useWidget != 'N'"
		class="fixed bottom-0 right-0 relative l24Widget"
		:style="{
			zIndex: wstore.zIndex,
		}"
	>
		<Transition name="fade" mode="out-in">
			<div
				class="relative l24-ring"
				v-if="initLoad"
				:style="{
					marginBottom: wstore.marginBottom,
					marginRight: wstore.marginRight,
				}"
				@mouseenter="mouseOver"
				@mouseleave="mouseOut"
			>
				<!-- 라이브 동영상 툴팁-->
				<Transition :name="!isTooltip ? 'box-slide-right' : 'box-slide-left'">
					<div
						v-if="
							isTooltip &&
							PS?.post_type == 'live' &&
							wstore.useWidget == 'Y' &&
							(wstore.tryPopup == 'N' || !isPopupRunMore)
						"
						class="tooltipWrap drop-shadow-2xl"
						style="aspect-ratio: 9/13"
					>
						<div style="position: relative">
							<div class="tooltipCloseBtn" @click="isTooltip = false">
								<img
									src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/6fd67d9417b4f3422ccd27af5ea99611.svg"
									alt=""
									style="width: 14px; height: 14px"
								/>
							</div>

							<div class="tooltipContent" @click="popupOpen">
								<div class="flex">
									<div class="flex-1 pointer" @click="popupOpen">
										<div class="flex items-center">
											<div class="liveBadge">
												<div class="IconEqual"></div>
												<div>LIVE</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="wstore.tryPopup != 'Y' || !isPopupRunMore">
								<UiHls
									:hlsUrl="
										wstore.livePreview
											? PS.play_url
											: `https://zoipt8ss2701.edge.naverncp.com/live/video/${PS.ui.channel_id}/360p-9-16-2/playlist.m3u8`
									"
									:autoplay="true"
									:muted="true"
									:loop="true"
									:aspectRatio="'9/13'"
									:poster="PS.thumb_url"
									:id="`sdfksdfkjlk11`"
								/>
							</div>
						</div>
					</div>
				</Transition>
				<!-- 라이브 동영상 툴팁-->
				<!-- 라이브 예고 툴팁-->
				<Transition :name="!isTooltip ? 'box-slide-right' : 'box-slide-left'">
					<div
						v-if="
							isTooltip &&
							PS?.post_type != 'live' &&
							wstore.useWidget == 'Y' &&
							wstore.tryPopup == 'N' &&
							PS?.tr_info &&
							PS?.tr_no == String(PS?.tr_info?.no)
						"
						class="tooltipWrap drop-shadow-2xl"
						style="aspect-ratio: 9/13"
						:style="{
							background: `url(${PS.tr_info?.thumb_url}) center center / cover no-repeat`,
							backgroundColor: '#000',
						}"
					>
						<div style="position: relative; height: 100%">
							<div class="tooltipCloseBtn" style="z-index: 30" @click="isTooltip = false">
								<img
									src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/6fd67d9417b4f3422ccd27af5ea99611.svg"
									alt=""
									style="width: 14px; height: 14px"
								/>
							</div>

							<div class="tooltipContent" @click="popupOpen">
								<div class="flex">
									<div class="flex-1 pointer" @click="popupOpen">
										<img
											src="https://tkmenfxu2702.edge.naverncp.com/profile/202108/9a7d6495c81e9a02b00deaeb11ef429b.svg"
											alt=""
											style="height: 20px"
										/>
									</div>
								</div>
							</div>

							<div
								style="
									width: 100%;
									height: 100%;
									position: absolute;
									display: flex;
									justify-content: center;
									align-items: end;
									z-index: 20;
									padding-top: 30px;
									cursor: pointer;
								"
								@click="popupOpen"
							>
								<div style="color: #fff; text-align: center; letter-spacing: -1px">
									<div
										style="
											margin-bottom: 15px;
											padding: 5px 8px;
											font-size: 13px;
											background-color: rgba(0, 0, 0, 0.3);
											border-radius: 10px;
										"
									>
										<div
											style="
												display: flex;
												gap: 3px;
												justify-content: center;
												align-items: center;
											"
										>
											<div style="margin-right: 5px">🗓</div>
											<div style="margin-right: 5px">{{ dateTxt11 }} ({{ dateTxtYo }})</div>
											<div>{{ dateTxtBan }}</div>
											<div>{{ dateTxt2 }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Transition>
				<!-- 라이브 예고 툴팁-->

				<!-- 마이크로페이지 -->
				<Transition :name="!isPopup ? 'box-slide-down' : 'box-slide-up'">
					<div v-if="isPopup" class="microW no-scrollbar wheelPraStop drop-shadow-2xl" id="l24Wmickf2">
						<microW
							:PS="PS"
							:config="config"
							:isScroll="isScroll"
							@popupClose="popupClose"
							:wstore="wstore"
						/>
					</div>
				</Transition>
				<!-- 마이크로페이지 -->

				<!-- 위젯버튼 -->
				<div
					class="l24-ring drop-shadow-lg overflow-hidden absolute pointer"
					:style="{
						borderRadius: wstore.borderRadius,
						zIndex: 10,
					}"
					@mousedown="isClickIng = true"
					@mouseup="isClickIng = false"
					:class="{
						clickBtnActive: isClickIng,
					}"
				>
					<img
						:src="wstore.badgeImage"
						class="aspect-ratio-1 object-contain drop-shadow-lg w-full h-full"
						alt=""
						v-if="!isPopup"
						@click="popupOpen"
					/>
					<div
						class="closeBtn drop-shadow-lg"
						:style="{
							borderRadius: wstore.borderRadius,
						}"
					>
						<img
							src="https://tkmenfxu2702.edge.naverncp.com/profile/202404/6fd67d9417b4f3422ccd27af5ea99611.svg"
							alt=""
							style="width: 26px; height: 26px"
							@click="popupClose"
						/>
					</div>
				</div>
				<!-- 위젯버튼 -->
			</div>
		</Transition>
	</div>
</template>

<style scoped>
.clickBtnActive {
	transform: scale(0.9);
}
.nostyleBtn {
	border: none;
	background: none;
	outline: none;
	cursor: pointer;
	width: 100%;
	height: 100%;
}
.livePopupArea {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}
@media screen and (min-width: 500px) {
	.livePopupItem {
		width: 360px;
		max-height: 800px;
		border-radius: 20px;
		background-color: #fff;
		overflow: hidden;
		height: 658px;
	}
	.livePopupSet {
		position: relative;
		width: 100%;
		height: calc(100% - 57px);
		max-height: 600px;
	}
	.livePopupSetArea {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.livePopupSetAreaTxt {
		padding: 15px;
	}
	.livePopupSetAreaTxt .tstitle {
		font-weight: 600;
		font-size: 16px;
		border-bottom: 1px solid rgba(241, 241, 241, 0.53);
		padding-bottom: 10px;
		padding-left: 5px;
		padding-right: 5px;
		line-height: 25px;
	}

	.livePopupSetAreaTxt .tsdate {
		font-size: 14px;
		font-weight: 600;
		padding-bottom: 10px;
		padding-top: 10px;
		padding-left: 5px;
		padding-right: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		background-color: #ffeb3b;
		border-radius: 10px;
	}
	.livePopupSetAreaTxt .tsdate.live {
		background-color: #ff0000;
		color: #fff;
	}
	.livePopupSetAreaTxt .tsdescription {
		font-size: 14px;

		border-top: 1px solid rgba(241, 241, 241, 0.53);
		padding-top: 10px;
		padding-left: 5px;
		padding-right: 5px;
		padding-bottom: 10px;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		word-wrap: break-word;
		line-height: 1.7em;
		min-height: 60px;
		max-height: 80px;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	.livePopupSetAreaSub {
		width: 100%;
		height: 100%;
	}

	.livePopupSetAreaSubBanner div {
		margin-bottom: 10px;
	}

	.livePopupClose {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		border-top: 1px solid #f3f3f3;
		background-color: #fafafa;
	}
	.livePopupClose > div {
		flex: 1;
		text-align: center;
		padding: 18px;
	}
	.popupHlsAsp {
		aspect-ratio: 1/1.25;
	}
	.popupThumbAsp {
		aspect-ratio: 1/1.25;
	}
}
@media screen and (max-width: 500px) {
	.livePopupThumbBadeBody {
		padding: 10px;
	}
	.livePopupThumbCloseBody {
		padding: 10px;
	}
	.livePopupThumbBody {
		padding: 15px 15px 8px 15px;

		overflow: hidden;
	}
	.popupHlsAsp {
		aspect-ratio: 16/13;
		border-radius: 20px;
	}
	.popupThumbAsp {
		aspect-ratio: 16/13;
		border-radius: 20px;
	}
	.livePopupArea {
		justify-content: center;
		align-items: end;
		z-index: 9999;
	}
	.livePopupItem {
		width: 100%;
		max-width: 480px;
		max-height: 70vh;
		border-radius: 20px 20px 0 0;
		background-color: #fff;
		overflow: hidden;
	}
	.livePopupSet {
		position: relative;
		width: 100%;
		height: calc(100% - 57px);
	}
	.livePopupSetArea {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.livePopupSetAreaTxt {
		padding: 15px;
		padding-top: 0px;
	}
	.livePopupSetAreaTxt .tstitle {
		font-weight: 600;
		font-size: 16px;
		border-bottom: 1px solid rgba(241, 241, 241, 0.53);
		padding-bottom: 10px;
		padding-left: 5px;
		padding-right: 5px;
		line-height: 25px;
	}

	.livePopupSetAreaTxt .tsdate {
		font-size: 14px;
		font-weight: 600;
		padding-bottom: 10px;
		padding-top: 10px;
		padding-left: 5px;
		padding-right: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		background-color: #ffeb3b;
		border-radius: 10px;
	}
	.livePopupSetAreaTxt .tsdate.live {
		background-color: #ff0000;
		color: #fff;
	}
	.livePopupSetAreaTxt .tsdescription {
		font-size: 14px;

		border-top: 1px solid rgba(241, 241, 241, 0.53);
		padding-top: 10px;
		padding-left: 5px;
		padding-right: 5px;
		padding-bottom: 10px;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		word-wrap: break-word;
		line-height: 1.7em;
		min-height: 60px;
		max-height: 80px;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	.livePopupSetAreaSub {
		width: 100%;
		height: 100%;
	}

	.livePopupSetAreaSubBanner div {
		margin-bottom: 10px;
	}

	.livePopupClose {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
		border-top: 1px solid #f3f3f3;
		background-color: #fafafa;
	}
	.livePopupClose > div {
		flex: 1;
		text-align: center;
		padding: 18px;
	}
}
.backdrop {
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 5;
}
.liveBadge {
	display: flex;
	background-color: red;
	font-size: 14px;
	color: #fff;
	align-items: center;
	justify-content: center;
	padding: 4px 10px 4px 10px;
	border-radius: 20px;
	margin-right: 5px;
}
.flex-1 {
	flex: 1;
}
.flex {
	display: flex;
}
.justify-center {
	justify-content: center;
}
.items-center {
	align-items: center;
}
.l24Widget * {
	box-sizing: border-box;
}
.closeBtn {
	width: 100%;
	height: 100%;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #e3e3e3;
	border-radius: 100px;
}
.microW {
	overflow-y: auto;
}
@media screen and (max-width: 500px) {
	.microW {
		background-color: #f3f3f3;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999;
		width: 100%;
		height: 100%;
	}

	.l24-ring {
		width: 45px;
		height: 45px;
		transition: all 0.2s ease-out;
	}

	.pc {
		display: none !important;
	}

	.tooltipContent {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: absolute;
		z-index: 3;
		height: 100%;
		padding: 0.7em;

		cursor: pointer;
	}
	.tooltipWrap {
		position: absolute;
		max-width: 200px;
		min-width: 170px;
		width: 18vw;
		min-height: 65px;

		z-index: 5;
		bottom: 54px;
		right: 0;
		overflow: hidden;
		border-radius: 20px;
		padding: 0;

		border: 0 solid #e3e3e3;
		background-color: #fff;
	}
	.tooltipTitle {
		font-size: 14px;
		font-weight: 600;
		color: #000;
	}

	.tooltipSubTitle {
		font-size: 12px;
		font-weight: 400;
		color: #6c6c6c;
		margin-top: 5px;
	}

	.tooltipCloseBtn {
		background-color: #eaeaea;
		width: 26px;
		height: 26px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 8;
	}
}
@media screen and (min-width: 500px) {
	.microW {
		border: 1px solid #e3e3e3;
		background-color: #f3f3f3;
		border-radius: 30px;
		width: 100vw;
		height: 88vh;
		max-width: 400px;
		max-height: 720px;
		position: absolute;
		bottom: 80px;
		right: 0;
	}

	.l24-ring {
		width: 60px;
		height: 60px;
		transition: all 0.2s ease-out;
	}

	.tooltipContent {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: absolute;
		z-index: 3;
		height: 100%;
		padding: 12px;

		cursor: pointer;
	}
	.tooltipWrap {
		position: absolute;
		max-width: 190px;
		min-width: 180px;
		width: 18vw;
		min-height: 65px;

		z-index: 5;
		bottom: 70px;
		right: 0;
		overflow: hidden;
		border-radius: 20px;
		padding: 0;

		border: 0 solid #e3e3e3;
		background-color: #fff;
	}
	.tooltipTitle {
		font-size: 14px;
		font-weight: 600;
		color: #000;
	}

	.tooltipSubTitle {
		font-size: 12px;
		font-weight: 400;
		color: #6c6c6c;
		margin-top: 5px;
	}

	.tooltipCloseBtn {
		background-color: #eaeaea;
		width: 26px;
		height: 26px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 8;
	}
}

.pointer {
	cursor: pointer;
}
.l24titleBody {
	width: 200px;
	height: 100%;
	position: absolute;
	z-index: 5;
	right: 0;
	display: flex;
	justify-content: end;
	align-items: center;
}
.l24titleb {
	font-size: 16px;
	font-weight: 700;
	color: #000000;
	background-color: #fff;
	height: calc(100% - 10px);
	display: flex;
	padding-left: 17px;
	padding-right: 45px;
	margin-right: 20px;
	align-items: center;
	border: 1px solid #cdcdcd;
	border-radius: 100px;
}
.w-full {
	width: 100%;
}
.h-full {
	height: 100%;
}
.drop-shadow-lg {
	filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}
.drop-shadow-2xl {
	filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}
.overflow-hidden {
	overflow: hidden;
}

.object-contain {
	object-fit: contain;
}
.object-cover {
	object-fit: cover;
}
.aspect-ratio-1 {
	aspect-ratio: 1/1;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
.bottom-0 {
	bottom: 0;
}
.right-0 {
	right: 0;
}

.fade-enter-active {
	transition: all 0.3s ease-out;
}
.fade-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-from {
	opacity: 0;
}
.fade-leave-to {
	opacity: 0;
}

.box-slide-left-enter-active {
	transition: all 0.2s ease-out;
}
.box-slide-left-leave-active {
	transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.box-slide-left-enter-from {
	transform: translateX(20%);
	opacity: 0;
}
.box-slide-left-leave-to {
	transform: translateX(-20%);
	opacity: 0;
}

.box-slide-right-enter-active {
	transition: all 0.2s ease-out;
}
.box-slide-right-leave-active {
	transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.box-slide-right-enter-from {
	transform: translateX(-20%);
	opacity: 0;
}
.box-slide-right-leave-to {
	transform: translateX(20%);
	opacity: 0;
}

.box-slide-up-enter-active {
	transition: all 0.2s ease-out;
}
.box-slide-up-leave-active {
	transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.box-slide-up-enter-from {
	transform: translateY(20%);
	opacity: 0;
}
.box-slide-up-leave-to {
	transform: translateY(-20%);
	opacity: 0;
}

.box-slide-down-enter-active {
	transition: all 0.2s ease-out;
}
.box-slide-down-leave-active {
	transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.box-slide-down-enter-from {
	transform: translateY(-20%);
	opacity: 0;
}
.box-slide-down-leave-to {
	transform: translateY(20%);
	opacity: 0;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
	-ms-overflow-style: none; /* 인터넷 익스플로러 */
	scrollbar-width: none; /* 파이어폭스 */
}

.scrollBar {
	overflow-y: auto;
}
.scrollBar::-webkit-scrollbar {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	display: block !important;
	width: 5px !important; /* 스크롤바의 너비 */
}

.scrollBar:hover::-webkit-scrollbar {
	width: 5px !important; /* 스크롤바의 너비 */
}

.scrollBar::-webkit-scrollbar-thumb {
	background: rgba(98, 98, 98, 0.1) !important; /* 스크롤바의 색상 */
	min-height: 20% !important;
	max-height: 30% !important;
}
.scrollBar::-webkit-scrollbar-track {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	background: rgba(68, 68, 68, 0.02) !important; /*스크롤바 뒷 배경 색상*/
}

.scrollBarHover {
	overflow-y: scroll;
}

.scrollBarHover.xs::-webkit-scrollbar {
	width: 4px !important; /* 스크롤바의 너비 */
}

.scrollBarHover.xs:hover::-webkit-scrollbar {
	display: block !important;
	width: 4px !important; /* 스크롤바의 너비 */
	transition: all 0.2s ease;
}

.scrollBarHover.xs::-webkit-scrollbar-thumb {
	background: rgba(98, 98, 98, 0.22) !important; /* 스크롤바의 색상 */
	min-height: 10% !important;
	max-height: 10% !important;
	border-radius: 10px !important;
}
.scrollBarHover.xs::-webkit-scrollbar-track {
	background: rgba(68, 68, 68, 0.02) !important; /*스크롤바 뒷 배경 색상*/
}

.scrollBarHover::-webkit-scrollbar {
	width: 10px !important; /* 스크롤바의 너비 */
}

.scrollBarHover:hover::-webkit-scrollbar {
	display: block !important;
	width: 10px !important; /* 스크롤바의 너비 */
	transition: all 0.2s ease;
}

.scrollBarHover::-webkit-scrollbar-thumb {
	background: rgba(98, 98, 98, 0.22) !important; /* 스크롤바의 색상 */
	min-height: 20% !important;
	max-height: 30% !important;
	border-radius: 10px !important;
}
.scrollBarHover::-webkit-scrollbar-track {
	background: rgba(68, 68, 68, 0.1) !important; /*스크롤바 뒷 배경 색상*/
}

.IconEqual {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-right: 2px;
	background-image: url(https://tkmenfxu2702.edge.naverncp.com/profile/202401/b5658b47341e97e00d84ac2e2abeda5f.png);
	background-size: 100% 18000%;
	animation: IconEqual_ani 2s steps(180) infinite;
	vertical-align: top;
}

@keyframes IconEqual_ani {
	from {
		background-position: 0 2160px;
	}

	to {
		background-position: 0 0;
	}
}

.gap-1 {
	gap: 1px;
}
.gap-2 {
	gap: 2px;
}
.gap-3 {
	gap: 3px;
}
.gap-4 {
	gap: 4px;
}
.gap-5 {
	gap: 5px;
}
.gap-6 {
	gap: 6px;
}
.gap-7 {
	gap: 7px;
}
.gap-8 {
	gap: 8px;
}
.gap-9 {
	gap: 9px;
}
.gap-10 {
	gap: 10px;
}
.gap-15 {
	gap: 15px;
}

.el-text2 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-wrap: break-word;
	max-height: 40px;

	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
</style>
