<script setup>
import { ref, computed, onMounted, markRaw, onUnmounted, watch, defineProps } from "vue";
import Hls from "hls.js";

const props = defineProps({
	muted: {
		type: Boolean,
		default: false,
	},
	autoplay: {
		type: Boolean,
		default: true,
	},
	hlsUrl: {
		type: String,
		required: true,
	},
	loop: {
		type: Boolean,
		default: false,
	},
	poster: {
		type: String,
		default: "https://tkmenfxu2702.edge.naverncp.com/profile/202312/4057e237fd97a9d94e5900d833e9bca8.png",
	},
	aspectRatio: {
		type: String,
		default: "9/16",
	},
	id: {
		type: String,
		default: "floatings",
	},
	start: {
		type: Number,
	},
	end: {
		type: Number,
	},
});

let hls = null;
let video = null;
let playUrl = null;
onMounted(() => {
	if (props.hlsUrl) {
		playUrl = props.hlsUrl;
		if (props.start && props.end) {
			playUrl = props.hlsUrl.replace("/shorts/", `/vodStart/${props.start}/vodEnd/${props.end}/shorts/`);
		}

		hls = new Hls();
		video = document.getElementById(`l24werPlayer_player_${props.id}`);
		if (Hls.isSupported()) {
			hls.loadSource(playUrl);
			hls.attachMedia(video);
		} else if (video.canPlayType("application/vnd.apple.mpegurl")) {
			video.src = playUrl;
		}
	}
});

onUnmounted(() => {
	if (Hls.isSupported()) {
		hls.destroy();
		hls = null;
	} else {
		video.src = "";
	}
});
</script>

<template>
	<video
		:id="`l24werPlayer_player_${id}`"
		:muted="muted ? true : false"
		:autoplay="autoplay ? true : false"
		:loop="loop ? true : false"
		poster="https://tkmenfxu2702.edge.naverncp.com/profile/202312/4057e237fd97a9d94e5900d833e9bca8.png"
		:style="{
			aspectRatio: aspectRatio,
			background: `url(${poster}) center center / cover no-repeat`,
		}"
		style="object-fit: cover"
		playsinline
		loading="lazy"
		class="w-full h-full"
	></video>
</template>

<style scoped>
.w-full {
	width: 100%;
}
.h-full {
	height: 100%;
}
</style>
